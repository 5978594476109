import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

interface IProps {
  onClick: () => void;
  text?: string;
  disabled?: boolean;
  className?: string;
  imgUrl?: string;
  admin?: boolean;
}

function Button({
  text,
  onClick,
  disabled,
  className,
  imgUrl,
  admin
}: IProps): React.ReactElement {
  const styles = useStyles();

  function renderContent(): React.ReactElement {
    if (imgUrl) {
      return <img src={imgUrl} alt={text} className={styles.icon} />;
    }
    return <span>{text}</span>;
  }

  return (
    <button
      className={
      clsx(styles.button,
        {
          [styles.disabled]: disabled,
          [styles.admin]: admin
        },
        className)
      }
      onClick={() => !disabled && onClick()}
      disabled={disabled}
      type="button"
    >
      {renderContent()}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  className: '',
  text: '',
  imgUrl: '',
  admin: false
};

export default Button;
