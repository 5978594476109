import React, { useCallback, useState } from 'react';
import { ILocationDownload } from 'src/interfaces/ILocation';
import { deleteLocationFB } from 'src/firebase/geoquery';
import { getLWL } from 'src/utils/string';
import { useStyles } from './styles';
import Images from '../Images';
import Map from '../Map';
import Button from '../Button';
import TextInput from '../TextInput';
import Modal from '../Modal';

function ViewLocation(location: ILocationDownload): React.ReactElement {
  const styles = useStyles();
  const {
    id,
    lineWordLetter,
    images
  } = location;
  const [showConfirmDeleteId, setShowConfirmDeleteId] = useState('');

  const renderDeleteModal = useCallback(() => {
    if (!showConfirmDeleteId) return null;
    return (
      <Modal
        isOpen={!!showConfirmDeleteId}
        onClose={() => setShowConfirmDeleteId('')}
        text="Are you sure you want to delete this location?"
        buttons={
          [
            {
              text: 'Cancel',
              key: 'cancel',
              onClick: () => setShowConfirmDeleteId('')
            },
            {
              text: 'Delete',
              key: 'delete',
              onClick: () => {
                deleteLocationFB(showConfirmDeleteId);
                setShowConfirmDeleteId('');
              },
              className: styles.delete
            }
          ]
        }
      />
    );
  }, [showConfirmDeleteId, styles.delete]);

  return (
    <div className={styles.container}>
      <div className={styles.id}>{id}</div>
      {images && <Images images={images.map((img) => ({ image: img }))} />}
      <Map {...location} />
      {
        lineWordLetter && (
          <>
            <TextInput
              textArea
              value={lineWordLetter}
              displayOnly
              noWrap
              className={styles.lwl}
            />
            <div>{`hello = ${getLWL(lineWordLetter, 'hello')}`}</div>
          </>
        )
      }
      <Button
        onClick={() => setShowConfirmDeleteId(id)}
        text="Delete"
        className={styles.delete}
      />
      {renderDeleteModal()}
    </div>
  );
}

export default ViewLocation;
