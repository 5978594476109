import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { IHint } from 'src/interfaces/IPuzzles';
import { formatSeconds } from 'src/utils/string';
import { IS_DEV } from 'src';
import { useFetchLocationImages } from 'src/hooks/FetchLocationImages';
import { useStyles } from './styles';
import Button from '../Button';
import Accordian from '../Accordian';
import Modal from '../Modal';
import Image from '../Image';

interface IProps {
  hints: IHint[];
  currentHintNumber: number;
  onNewHint: (hintNumber: number) => void;
  isPuzzleSolved: boolean;
  displayInline?: boolean;
}

function Hints({
  hints,
  currentHintNumber,
  onNewHint,
  isPuzzleSolved,
  displayInline
}: IProps): React.ReactElement {
  const styles = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animateButton, setAnimateButton] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const hintsRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<number>();
  const animationTimeoutRef = useRef<number>();
  const hintsWithImgs = useFetchLocationImages(hints);

  const getWaitTime = useCallback((hintNumber: number) => {
    const BASE_WAIT = IS_DEV ? 2 : 300; // 5 minutes in seconds
    return BASE_WAIT * hintNumber;
  }, []);

  const triggerBtnAnimation = useCallback(() => {
    setAnimateButton(true);
    animationTimeoutRef.current = window.setTimeout(() => {
      setAnimateButton(false);
    }, 1000);
  }, []);

  const startCountdown = useCallback(() => {
    if (isPuzzleSolved || currentHintNumber < 1 || currentHintNumber >= hintsWithImgs.length) {
      return;
    }

    const currentHint = hintsWithImgs[currentHintNumber - 1];
    const hintStartTime = currentHint.timeStamp || Date.now();
    const waitTime = getWaitTime(currentHintNumber);

    const updateCountdown = (): void => {
      const elapsed = (Date.now() - hintStartTime) / 1000;
      const remaining = Math.max(0, waitTime - elapsed);

      if (remaining <= 0) {
        clearInterval(intervalRef.current);
        setCountdown(null);
        triggerBtnAnimation();
      } else {
        setCountdown(remaining);
      }
    };

    updateCountdown();
    intervalRef.current = window.setInterval(updateCountdown, 100);
  }, [isPuzzleSolved, currentHintNumber, hintsWithImgs, getWaitTime, triggerBtnAnimation]);

  useEffect(() => {
    startCountdown();
    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(animationTimeoutRef.current);
    };
  }, [startCountdown]);

  const handleNextHint = (): void => {
    const newHintNumber = currentHintNumber + 1;
    if (newHintNumber <= hintsWithImgs.length) {
      onNewHint(newHintNumber);
      setTimeout(() => {
        const hintsContainer = hintsRef.current?.parentElement;
        if (!hintsContainer) return;
        hintsContainer.scrollTo({ behavior: 'smooth', top: hintsContainer.scrollHeight });
      }, 1000);
    }
  };

  const getButtonText = (): string => {
    if (isPuzzleSolved) return 'Puzzle Solved';
    if (currentHintNumber >= hintsWithImgs.length) return 'No More Hints';
    if (countdown !== null) return formatSeconds(countdown);
    return 'Get Hint';
  };

  function renderHints(): React.ReactElement {
    return (
      <div className={styles.hints} ref={hintsRef}>
        {
          hintsWithImgs.map((hint, index) => (
            <Accordian
              key={hint.text}
              isOpen={index < currentHintNumber}
            >
              <div className={styles.hint}>
                {hint.image?.src && <Image image={hint.image.src} alt={hint.text} />}
                <span>{`${index + 1}. `}</span>
                <span>{hint.text}</span>
              </div>
            </Accordian>
          ))
        }
      </div>
    );
  }

  if (displayInline) {
    return (
      <div className={styles.container}>
        <Button
          text={getButtonText()}
          onClick={handleNextHint}
          className={clsx(styles.nextHintBtn, { [styles.animateNewHintBtn]: !isPuzzleSolved && animateButton })}
          disabled={isPuzzleSolved || !!countdown || currentHintNumber >= hintsWithImgs.length}
        />
        {renderHints()}
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Hint ${currentHintNumber} of ${hintsWithImgs.length}`}
        content={renderHints()}
        buttons={
          [
            {
              key: 'next-hint',
              text: getButtonText(),
              onClick: () => handleNextHint(),
              className: clsx(styles.nextHintBtn, { [styles.animateNewHintBtn]: !isPuzzleSolved && animateButton }),
              disabled: isPuzzleSolved || !!countdown || currentHintNumber >= hintsWithImgs.length
            }
          ]
        }
      />
      <Button
        onClick={() => setIsModalOpen(true)}
        imgUrl={`${process.env.PUBLIC_URL}/icons/bulb.svg`}
      />
    </div>
  );
}

Hints.defaultProps = {
  displayInline: false
};

export default Hints;
