/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { IS_DEV } from 'src';
import { ICell, IWord } from 'src/interfaces/IWordSearch';
import { useResize } from 'src/hooks/Resize';
import { IPuzzleStep, IWordSearch } from 'src/interfaces/IPuzzles';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { checkAnswer } from 'src/utils/string';
import { scrollDown } from 'src/utils/dom';
import { useTheme } from '@material-ui/core';
import Cell from './Cell';
import { useStyles } from './styles';
import {
  isSwipeValid,
  getHighlightStyle,
  isCellInList
} from './utils';
import Hints from '../Hints';
import Button from '../Button';
import TextInput from '../TextInput';

function Wordsearch(puzzleStep: IWordSearch): React.ReactElement {
  const styles = useStyles();
  const { data: puzzle, onComplete, isComplete } = puzzleStep;
  const { foundWords: foundWordsFB, currentHintNumber = 0 } = puzzle;
  const [foundWords, setFoundWords] = useState(foundWordsFB);
  const [startingCell, setStartingCell] = useState<ICell|null>(null);
  const [currentCell, setCurrentCell] = useState<ICell|null>(null);
  const [hightlight, setHighlight] = useState<CSSProperties>();
  const [cellSize, setCellSize] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [isWSComplete, setIsWSComplete] = useState(false);
  const puzzleContainer = useRef<HTMLDivElement>(null);
  const { updateStep } = useContext(QuestContext);
  const theme = useTheme();

  const updateCellSize = useCallback(() => {
    if (!puzzleContainer.current) return;
    const firstCell = puzzleContainer.current.getElementsByTagName('button')[0];
    if (!firstCell) return;
    const { width } = firstCell.getBoundingClientRect();
    setCellSize(width);
  }, [puzzleContainer]);

  // words can be found backwards and forwards, we only care if they are found so .includes won't work here
  const isWordFound = useCallback((word: IWord) => foundWords.find((w) => w.word === word.word), [foundWords]);

  useEffect(() => {
    // clear state when we have a new puzzle
    setStartingCell(null);
    setCurrentCell(null);
    setHighlight({});
  }, [puzzle]);

  useEffect(() => {
    setHighlight(getHighlightStyle(startingCell, currentCell, cellSize));
  }, [startingCell, currentCell, cellSize]);

  useEffect(() => { setFoundWords(foundWordsFB); }, [foundWordsFB]);
  useEffect(() => {
    if (!isWSComplete && puzzle.words.length === foundWords.length) {
      setIsWSComplete(true);
      if (!isComplete) {
        scrollDown(theme.transitionTime);
      }
    }
  }, [isComplete, isWSComplete, foundWords, puzzle.words.length, theme.transitionTime]);

  useResize(updateCellSize);

  const clearHighlight = useCallback(() => {
    setHighlight({});
    setStartingCell(null);
    setCurrentCell(null);
  }, []);

  const setStart = useCallback((cell: ICell) => {
    setStartingCell(cell);
    setCurrentCell(cell);
  }, []);

  const updateFoundWords = useCallback((newFoundWords: IWord[]) => {
    const newPuzzleStepState: IPuzzleStep = {
      ...puzzleStep,
      data: {
        ...puzzle,
        foundWords: newFoundWords
      }
    };
    setFoundWords(newFoundWords);
    updateStep(newPuzzleStepState);
  }, [puzzle, puzzleStep, updateStep]);

  const onWordFound = useCallback((word: IWord) => {
    // since some words can be found forwards and backwards we only want to find them once
    const foundWord = isWordFound(word) || word;
    const newFoundWords = [...foundWords, foundWord];
    updateFoundWords(newFoundWords);
  }, [isWordFound, foundWords, updateFoundWords]);

  const onDragEnd = useCallback(() => {
    if (startingCell && currentCell) {
      const match = isSwipeValid(startingCell, currentCell, puzzle.words);
      if (match) {
        onWordFound(match);
      }
      clearHighlight();
    }
  }, [clearHighlight, currentCell, onWordFound, startingCell, puzzle.words]);

  function renderPuzzleGrid(): ReactElement {
    return (
      <div
        className={styles.squareContainer}
        style={{ paddingBottom: `${(puzzle.grid.length / puzzle.grid[0].length) * 100}%` }}
      >
        <div
          className={clsx(styles.puzzleContainer, { [styles.complete]: isWSComplete })}
          ref={puzzleContainer}
        >
          {
            puzzle.grid.map((row, rowIdx) => (
              <div
                key={`${JSON.stringify(row)}${rowIdx}`}
                className={styles.row}
              >
                {
                  row.map((cellVal, colIdx) => (
                    <Cell
                      key={`${colIdx}${rowIdx}`}
                      x={colIdx}
                      y={rowIdx}
                      setStartingCell={setStart}
                      setCurrentCell={setCurrentCell}
                      letter={cellVal}
                      hide={currentHintNumber > 1}
                      removeCell={currentHintNumber > 2}
                      isLetterUsed={isCellInList({ x: colIdx, y: rowIdx }, puzzle.usedCells)}
                      isWordsearchComplete={isWSComplete}
                    />
                  ))
                }
              </div>
            ))
          }
          {
            foundWords.map((word) => {
              let [highlightStart] = word.path;
              let highlightEnd = word.path[word.path.length - 1];
              if (word.foundBackwards) {
                highlightStart = word.path[word.path.length - 1];
                [highlightEnd] = word.path;
              }
              const style = getHighlightStyle(highlightStart, highlightEnd, cellSize);
              return (
                <div
                  key={word.word}
                  style={style}
                  className={clsx(styles.highlight, { [styles.highlightHidden]: currentHintNumber > 2 })}
                />
              );
            })
          }
          <div
            className={styles.highlight}
            style={hightlight}
          />
        </div>
      </div>
    );
  }

  function renderInfo(): ReactElement {
    const halfwayPoint = Math.ceil(puzzle.words.length / 2);
    const firstHalfOfWords = puzzle.words.slice(0, halfwayPoint);
    const secondHalfOfWords = puzzle.words.slice(halfwayPoint);

    return (
      <div className={styles.info}>
        <div className={clsx(styles.wordsToFind)}>
          <div>
          {
            firstHalfOfWords.map((word) => (
              <div
                key={word.word}
                className={clsx({ [styles.found]: isWordFound(word) })}
              >
                {word.word}
              </div>
            ))
          }
          </div>
          <div className={styles.secondHalfOfWords}>
          {
            secondHalfOfWords.map((word) => (
              <div
                key={word.word}
                className={clsx({ [styles.found]: isWordFound(word) })}
              >
                {word.word}
              </div>
            ))
          }
          </div>
        </div>
      </div>
    );
  }

  function showDeveloperStuff(): ReactElement {
    function completePuzzle(): void {
      const solvedWords: IWord[] = [];
      puzzle.words.forEach((word) => {
        const match = isSwipeValid(word.path[0], word.path[word.path.length - 1], puzzle.words);
        if (match) {
          solvedWords.push(match);
        }
      });
      updateFoundWords(solvedWords);
    }

    return (
      <Button
        onClick={() => completePuzzle()}
        text="Complete Puzzle"
        className={styles.completePuzzle}
      />
    );
  }

  function renderAnswerInput(): ReactElement {
    if (!isWSComplete) return <></>;
    const onInputChange = (theirAnswer: string):void => {
      setCurrentAnswer(theirAnswer);
      if (!isComplete && checkAnswer(theirAnswer, puzzle.answer)) {
        onComplete();
      }
    };

    const getHint = (newHintNo: number):void => {
      const newPuzzleStepState: IPuzzleStep = {
        ...puzzleStep,
        data: {
          ...puzzle,
          currentHintNumber: newHintNo
        }
      };
      updateStep(newPuzzleStepState);
    };

    return (
      <>
        {
          puzzle.hints && (
            <Hints
              hints={puzzle.hints}
              currentHintNumber={currentHintNumber ?? 0}
              onNewHint={getHint}
              isPuzzleSolved={isComplete}
              displayInline
            />
          )
        }
        <TextInput
          onChange={onInputChange}
          displayOnly={isComplete}
          value={isComplete ? puzzle.answer : currentAnswer}
        />
      </>
    );
  }

  return (
    <div
      className={styles.container}
      onPointerUp={onDragEnd}
      onTouchEnd={onDragEnd}
      role="presentation"
    >
      {IS_DEV && showDeveloperStuff()}
      {renderPuzzleGrid()}
      {renderInfo()}
      {renderAnswerInput()}
    </div>
  );
}

export default Wordsearch;
