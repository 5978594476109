import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  container: {

  },
  lwlInstructions: {
    margin: '10px 0px'
  },
  lwlInstruction: {
    display: 'flex',
    gap: '5px',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    '&:before': {
      content: '"•"',
      display: 'block',
      height: '10px'
    }
  },
  lwlExampleText: {
    textAlign: 'center'
  },
  lwlExampleAnswer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    gap: '10px',
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    marginBottom: '40px'
  },
  lwlExampleChunk: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  clue: {
    textAlign: 'center',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize
  },
  lwlCode: {
    textAlign: 'center',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    marginTop: '20px'
  }
}));
