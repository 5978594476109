import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      fontFamily: 'inherit, treasureMap',
      '-webkit-touch-callout': 'none !important',
      userSelect: 'none',
      '-webkit-user-select': 'none' /* Disable long-press on iOS */
    },
    'html, body, #root': {
      height: '100%',
      margin: 0,
      backgroundColor: '#000000',
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/backgroundImage.jpg)`,
      overscrollBehavior: 'none',
      overscrollBehaviorBlock: 'none'
    }
  }
}));
