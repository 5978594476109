import React from 'react';
import { useStyles } from './styles';

type IProps = {
  image: string;
  alt?: string;
  onDelete?: () => void;
};

function Image({ image, alt, onDelete }: IProps): React.ReactElement {
  const styles = useStyles();

  return (
    <div
      className={styles.imgContainer}
    >
      <img src={image} alt={alt} className={styles.img} />
      {
        onDelete && (
          <button
            type="button"
            className={styles.delImage}
            onClick={onDelete}
          >
            <span role="img" aria-label="delete">&#x274c;</span>
          </button>
        )
      }
    </div>
  );
}

Image.defaultProps = {
  alt: '',
  onDelete: undefined
};

export default Image;
