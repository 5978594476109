import React, { ComponentProps } from 'react';
import { useStyles } from './styles';
import Image from '../Image';

type IProps = {
  images: ComponentProps<typeof Image>[];
  onDelete?: (img: string) => void;
};

function Images({ images, onDelete }: IProps): React.ReactElement {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {
        images.map((image) => (
          <Image
            key={image.image}
            onDelete={onDelete ? () => onDelete(image.image) : undefined}
            {...image}
          />
        ))
      }
    </div>
  );
}

Images.defaultProps = {
  onDelete: undefined
};

export default Images;
