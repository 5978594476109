import { createTheme } from '@material-ui/core/styles';

const headerFonts = [
  'treasureMap'
].join(',');

const bodyFonts = [
  'freebooter'
].join(',');

export const theme = createTheme(
  {
    // custom options
    loading: {
      removeAnimationTime: 400
    },
    transitionTime: 2000,
    maxWidth: '400px',
    pagePadding: '60px 20px 20px 20px',
    typography: {
      fontFamily: headerFonts,
      h1: { fontFamily: headerFonts },
      h2: { fontFamily: headerFonts },
      h3: { fontFamily: headerFonts },
      h4: { fontFamily: headerFonts },
      h5: { fontFamily: headerFonts },
      h6: { fontFamily: headerFonts },
      body1: {
        fontFamily: bodyFonts,
        fontSize: '1.5em'
      },
      body2: {
        fontFamily: bodyFonts,
        fontSize: '1em'
      },
      allVariants: {
        color: '#000'
      }
    }
  }
);
