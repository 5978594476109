/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ICell } from 'src/interfaces/IWordSearch';
import { useStyles } from './styles';

interface IProps {
  x: number;
  y: number;
  letter: string;
  setStartingCell: (c: ICell) => void;
  setCurrentCell: (c: ICell) => void;
  isLetterUsed: boolean;
  hide: boolean;
  removeCell: boolean;
  isWordsearchComplete: boolean;
}

function Cell({
  x,
  y,
  letter,
  setStartingCell,
  setCurrentCell,
  isLetterUsed,
  hide,
  removeCell,
  isWordsearchComplete
}: IProps): React.ReactElement {
  const styles = useStyles();

  const handlePointerDown = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    if (isWordsearchComplete) return;
    if (e.target instanceof Element) {
      // this is needed to allow the pointer to move outside the cell to other letters
      e.target.releasePointerCapture(e.pointerId);
    }
    setStartingCell({ x, y });
  }, [setStartingCell, x, y, isWordsearchComplete]);

  return (
    <button
      className={
        clsx(styles.cell, {
          [styles.hidden]: isLetterUsed && hide,
          [styles.removeCell]: removeCell && isLetterUsed && hide
        })
      }
      onPointerDown={handlePointerDown}
      onPointerEnter={() => setCurrentCell({ x, y })}
      type="button"
    >
      {letter}
    </button>
  );
}

export default Cell;
