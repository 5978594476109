import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  squareContainer: {
    width: '100%',
    paddingBottom: '100%',
    height: '0',
    position: 'relative'
  },
  puzzleContainer: {
    position: 'absolute',
    userSelect: 'none',
    '-webkit-user-select': 'none',
    touchAction: 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  complete: {
    touchAction: 'auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: '1',
    overflow: 'hidden'
  },
  highlight: {
    pointerEvents: 'none',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/rip.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    transition: 'width 0.2s, opacity 0.3s',
    width: '0px',
    height: '0px'
  },
  highlightHidden: {
    opacity: '0'
  },
  info: {
    textTransform: 'uppercase',
    margin: '20px 0px',
    alignSelf: 'stretch'
  },
  wordsToFind: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    margin: 'auto'
  },
  secondHalfOfWords: {
    textAlign: 'right'
  },
  hint: {
    textAlign: 'center'
  },
  found: {
    textDecoration: 'line-through'
  },
  completePuzzle: {
    margin: '10px auto'
  }
}));
