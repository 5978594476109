import { makeWordSearch } from 'src/components/Wordsearch/utils';
import {
  ICaeserCipher,
  IClueInput,
  IHint,
  ILocationInput,
  IQuest,
  ISafe,
  IStretchedText,
  IWordSearch,
  PUZZLE_TYPE
} from 'src/interfaces/IPuzzles';
import {
  generateId,
  getLWL,
  getQuestName,
  getQuestTreasure,
  getRandomSafeNumber
} from 'src/utils/string';

function createWordsearch(secretMessage: string, answer: string): IWordSearch {
  return {
    type: PUZZLE_TYPE.WORDSEARCH,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: makeWordSearch(secretMessage, answer)
  };
}

function createSafe(code: string): ISafe {
  return {
    type: PUZZLE_TYPE.SAFE,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      code,
      hints: [
        {
          text: 'It looks like a safe',
          timeStamp: 0
        },
        {
          text: 'How does a safe work?',
          timeStamp: 0
        },
        {
          text: 'Changing direction changes numbers',
          timeStamp: 0
        }
      ]
    }
  };
}

function createLocationInput(lat: number, lng: number, answer: string, clue: string, hints?: IHint[]): ILocationInput {
  return {
    type: PUZZLE_TYPE.LOCATION_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      lat,
      lng,
      answer,
      clue,
      hints
    }
  };
}

function createLWL(
  lat: number,
  lng: number,
  answer: string,
  passageDescription: string,
  passageText: string,
  hints?: string[]
): ILocationInput {
  return {
    type: PUZZLE_TYPE.LOCATION_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      lat,
      lng,
      answer,
      clue: `Now go and look for the passage "${passageDescription}"`,
      lwl: {
        passageDescription,
        passageText,
        lwlCode: `${getLWL(passageText, answer)}`
      },
      hints: hints?.map((hint) => ({ text: hint }))
    }
  };
}

function createCaeserCipher(answer: string, secretMessage?: string): ICaeserCipher {
  return {
    type: PUZZLE_TYPE.CAESER_CIPHER,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      secretMessage,
      answer,
      hints: [
        {
          text: 'Seems like some sort of code',
          timeStamp: 0
        },
        {
          text: 'I need to offset the letters',
          timeStamp: 0
        },
        {
          text: 'Is there a rotation which makes sense',
          timeStamp: 0
        }
      ]
    }
  };
}

function createStretchedText(secretMessage: string, decoy: string, answer: string, hints?: string[]): IStretchedText {
  return {
    type: PUZZLE_TYPE.STRETCHED_TEXT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      secretMessage,
      decoy,
      answer,
      hints: [
        {
          text: 'Sometimes you need a fresh perspective',
          timeStamp: 0
        },
        {
          text: 'Try another direction',
          timeStamp: 0
        },
        {
          text: 'Try looking from a different angle',
          timeStamp: 0
        },
        ...(hints ? hints.map((hint) => ({ text: hint })) : [])
      ]
    }
  };
}

function createClueInput(answer: string, clue?: string[], hints: string[] = []): IClueInput {
  return {
    type: PUZZLE_TYPE.CLUE_INPUT,
    id: generateId(),
    isComplete: false,
    onComplete: () => {},
    data: {
      clue,
      answer,
      hints: hints.map((hint) => ({ text: hint })),
      currentHintNumber: 0
    }
  };
}

export function createQuest(): IQuest {
  const questName = getQuestName();
  return {
    id: generateId(),
    name: questName,
    steps: [
      createWordsearch('Full moon party beach', 'Haad Rin'),
      createCaeserCipher('Thank you', 'What does khop khun kha mean in Thai'),
      createStretchedText('Pirate', 'Take the P', 'Irate', [
        'What happens if you take the p from a pirate?'
      ]),
      createLocationInput(
        9.7573547,
        99.9620877,
        'Flower',
        'What\'s in the mermaids right hand?',
        [
          { text: 'Look for a painting' },
          { text: 'Graffiti on a wall' },
          {
            text: 'Can you see her here?',
            image: {
              locationDocId: 'IorlKNNPwuWI5RddekY7',
              imageIdx: 1
            }
          },
          {
            text: 'How about now?',
            image: {
              locationDocId: 'IorlKNNPwuWI5RddekY7',
              imageIdx: 0
            }
          }
        ]
      ),
      createClueInput('map', [
        'I can show you riches, I can lead you to doom,',
        'Hold me tight, for the right way looms.',
        'Some call me treasure, some call me cursed,',
        'Without me, a sailor’s fate may be the worst.',
        'What am I?'
      ], [
        'I’m often found on an old ship, but I’m not a piece of wood.',
        'I guide sailors, but I don’t speak or shout.',
        'I’m made of paper, but worth more than gold to those who seek.',
        'X marks the spot, they say.',
        'You’d unfold me to find your way to buried treasure.'
      ]),
      createLocationInput(
        9.7568111,
        99.9613069,
        '7342',
        'Find the special Coconut',
        [
          {
            text: 'Just outside the restaurant',
            image: {
              locationDocId: 'LHvjY4bgoJWLIuTe1ndZ',
              imageIdx: 2
            }
          },
          {
            text: 'One is not like the others',
            image: {
              locationDocId: 'LHvjY4bgoJWLIuTe1ndZ',
              imageIdx: 1
            }
          },
          {
            text: 'What\'s inside?',
            image: {
              locationDocId: 'LHvjY4bgoJWLIuTe1ndZ',
              imageIdx: 0
            }
          }
        ]
      ),
      createLocationInput(
        9.759955108644064,
        99.96168086559037,
        'KOKHAO',
        'Name of the boat',
        [
          {
            text: 'You might need to swim',
            image: {
              locationDocId: 'IQDqGQALejptTH4ZrIhY',
              imageIdx: 0
            }
          },
          {
            text: 'Good Luck',
            image: {
              locationDocId: 'IQDqGQALejptTH4ZrIhY',
              imageIdx: 1
            }
          }
        ]
      ),
      createSafe(getRandomSafeNumber()),
      createLWL(9.763575, 99.962006, getQuestTreasure(questName), 'Seaflower forecasting stone',
      // eslint-disable-next-line @typescript-eslint/indent
      `Seaflower forecasting stone
      forecast condition
      stone is wet raining
      strong is dry not raining
      shadow on ground sunny
      white on top snowing
      cant see stone foggy
      swinging stone windy
      stone gone tonado`)
    ]
  };
}
