import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => createStyles({
  button: {
    outline: 'none',
    background: 'none',
    border: 'none',
    fontSize: '1.5em',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/oldPaper.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: theme.typography.body1.color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 15px',
    boxShadow: '0px 0px 3px #222',
    borderRadius: '5px',
    cursor: 'pointer',
    '& span': {
      marginTop: '3px'
    },
    '&:enabled:active': {
      boxShadow: '0px 0px 1.5px #222'
    }
  },
  disabled: {
    '&&': {
      opacity: '0.5'
    }
  },
  admin: {
    '&&': {
      background: 'none',
      boxShadow: 'none',
      padding: '5px'
    }
  },
  icon: {
    height: '30px',
    width: '30px'
  }
}));
