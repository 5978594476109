import React, {
  useCallback,
  useContext,
  useMemo
} from 'react';
import { ILocationInput } from 'src/interfaces/IPuzzles';
import { checkAnswer, getLWL } from 'src/utils/string';
import { QuestContext } from 'src/providers/quest/QuestContext';
import { useStyles } from './styles';
import TextInput from '../TextInput';
import Map from '../Map';
import Hints from '../Hints';

const lineWordLetterExample = `The quick brown
fox jumps over
the lazy dog`;

const LWL_EXAMPLE = 'SECRET';

function LocationInput(puzzleStep: ILocationInput): React.ReactElement {
  const {
    data,
    onComplete,
    isComplete
  } = puzzleStep;
  const styles = useStyles();
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const { updateStep } = useContext(QuestContext);
  const xMark = {
    lat: data.lat,
    lng: data.lng
  };

  const onInputChange = useCallback((theirAnswer: string) => {
    setCurrentAnswer(theirAnswer);
    if (!isComplete && checkAnswer(theirAnswer, data.answer)) {
      onComplete();
    }
  }, [isComplete, data.answer, onComplete]);

  const exampleCode = useMemo(() => getLWL(lineWordLetterExample, LWL_EXAMPLE), []);

  const onNewHint = useCallback(() => {
    const newState: ILocationInput = {
      ...puzzleStep,
      data: {
        ...data,
        hints: data.hints?.map((hint, index) => (
          { ...hint, timeStamp: index === data.currentHintNumber ? Date.now() : hint.timeStamp }
        )),
        currentHintNumber: (data.currentHintNumber ?? 0) + 1
      }
    };
    updateStep(newState);
  }, [updateStep, puzzleStep, data]);

  const renderLWLInstructions = useCallback(() => {
    if (!data.lwl) return null;
    const letterCodes = exampleCode.split(/(?=\[)/);
    return (
      <>
        <div className={styles.clue}>[Line, Word, Letter] Cypher</div>
        <div className={styles.lwlInstructions}>
          <div className={styles.lwlInstruction}>Find passage of text</div>
          <div className={styles.lwlInstruction}>Take each 3-number chunk [like 1-2-3]</div>
          <div className={styles.lwlInstruction}>1st = go to that line in the text</div>
          <div className={styles.lwlInstruction}>2nd = go to that word in the line</div>
          <div className={styles.lwlInstruction}>3rd = pick that letter from the word</div>
        </div>
        <TextInput
          value={lineWordLetterExample}
          className={styles.lwlExampleText}
          displayOnly
          noWrap
          textArea
        />
        <div className={styles.lwlExampleAnswer}>
          {
          letterCodes.map((letterCode, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${letterCode}_${index}`}
              className={styles.lwlExampleChunk}
            >
              <div>{letterCode}</div>
              <div>{LWL_EXAMPLE[index]}</div>
            </div>
          ))
          }
        </div>
      </>
    );
  }, [
    exampleCode,
    data.lwl,
    styles.clue,
    styles.lwlInstructions,
    styles.lwlInstruction,
    styles.lwlExampleText,
    styles.lwlExampleAnswer,
    styles.lwlExampleChunk
  ]);

  return (
    <div className={styles.container}>
      {renderLWLInstructions()}
      <div className={styles.clue}>{data.clue}</div>
      <Map {...xMark} />
      {data.lwl?.lwlCode && <div className={styles.lwlCode}>{data.lwl.lwlCode}</div>}
      {
        data.hints ? (
          <Hints
            hints={data.hints}
            currentHintNumber={data.currentHintNumber ?? 0}
            onNewHint={onNewHint}
            isPuzzleSolved={isComplete}
          />
        ) : null
      }
      <TextInput
        onChange={onInputChange}
        displayOnly={isComplete}
        value={isComplete ? data.answer : currentAnswer}
      />
    </div>
  );
}

export default React.memo(LocationInput);
