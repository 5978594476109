import React, {
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { IPosition } from 'src/interfaces/ILocation';
import { addNewLocationFB } from 'src/firebase/geoquery';
import { UtilsContext } from 'src/providers/utils/UtilsContext';
import useImageCompressor from 'src/hooks/ImageCompression';
import { useStyles } from './styles';
import AdminMap from '../AdminMap';
import TextInput from '../TextInput';
import Button from '../Button';
import Images from '../Images';

function UploadLocation(): React.ReactElement {
  const styles = useStyles();
  const { showToast } = useContext(UtilsContext);
  const [position, setPosition] = useState<IPosition>();
  const [lineWordLetter, setLineWordLetter] = useState('');
  const [clue, setClue] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState<Set<string>>(new Set());
  const [isAddingImages, setIsAddingImages] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const { addImage, removeImage } = useImageCompressor(setImages, (err) => showToast(err, true), true);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>):void => {
    const { files } = event.target;
    if (files) {
      setFileNames(Array.from(files).map((file) => file.name));
      setIsAddingImages(true);
      const addingImages = Array.from(files).map(addImage);
      Promise.all(addingImages).then(() => {
        setIsAddingImages(false);
      });
    }
  };

  const addNewLocation = useCallback(async () => {
    if (!position || !clue) {
      showToast(`Missing data, position ${JSON.stringify(position)}, clue ${clue}`);
      return;
    }
    setIsUploading(true);
    await addNewLocationFB({
      lineWordLetter,
      clue,
      images: [...images],
      ...position
    });
    showToast('Location uploaded');
    setClue('');
    setImages(new Set([]));
    setLineWordLetter('');
    setPosition(undefined);
    setIsUploading(false);
    setFileNames([]);
  }, [clue, images, lineWordLetter, position, showToast]);

  return (
    <div className={styles.container}>
      <AdminMap spot={position} setSpot={setPosition} />
      <TextInput
        textArea
        onChange={setClue}
        value={clue}
        placeholder="Clue"
      />
      <Images
        images={[...images].map((img) => ({ image: img }))}
        onDelete={(image) => removeImage(image)}
      />
      <Button
        onClick={() => uploadImageRef.current?.click()}
        text="Add Images"
        disabled={isAddingImages}
      />
      <input
        ref={uploadImageRef}
        className={styles.imgInput}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        multiple
      />
      <div>
        {fileNames.map((file) => <div>{file}</div>)}
      </div>
      <TextInput
        textArea
        onChange={(val) => setLineWordLetter(val)}
        value={lineWordLetter}
        placeholder="line-word-letter Text"
        className={styles.lwlInput}
        noWrap
      />
      <Button
        onClick={addNewLocation}
        text="Upload Location"
        disabled={!clue || !position || isUploading}
      />
    </div>
  );
}

export default UploadLocation;
